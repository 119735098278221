import {
  Box,
  // Button,
  // Heading,
  // Image,
} from "../../../components/StyledComponent";
import {
  faChevronUp,
  faChevronDown,
  faPlay,
  faPause,
  faStop,
  faForwardStep,
  faBackwardStep,
  faShareFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import { isMobile } from "../../../constants";
import $ from "jquery";
import "jqueryui";
import "jquery-ui-touch-punch";

import { CriOS, iOS } from "../../../util";

import PAPER_BG from "../../../assets/images/Background.png";
import LOGO from "../../../assets/images/chenayder_txt.png";
import CHEYNADER from "../../../assets/images/chenayder-2.jpg";
import BANDISTOWN from "../../../assets/images/Bandsintown_logo.svg.png";

import DISK from "../../../assets/images/Disk.png";
import TOUR from "../../../assets/images/Tour.png";
import VIDEOS from "../../../assets/images/Video.png";
import STORE from "../../../assets/images/Store.png";
import QMARK from "../../../assets/images/Question Mark.png";
import VID_PLAYER from "../../../assets/images/Video player.png";

import VIDEO_D from "../../../assets/videos/Desktop Hero3.mp4";
import VIDEO_M from "../../../assets/videos/Mobile Hero3.mp4";
import POSTER_D from "../../../assets/images/Desktop Hero.gif";
import POSTER_M from "../../../assets/images/Mobile Hero.gif";

import CURSOR from "../../../assets/images/chenayder_cursor.svg";

import BLUE_OBLIVION from "../../../assets/images/music/blue-oblivion.jpg";

import { socials, musicData, videoData } from "./data";
import {
  getBoxOffsets,
  isTextPosMatchedToBox,
  makeNewPosition,
} from "./dragChecker";

const Slider = styled.input`
  appearance: none;

  ::-webkit-slider-thumb {
    appearance: none;
    height: 8px;
    width: 8px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #e62272;
    cursor: pointer;
  }
`;

const VideoPlayer = styled.video``;

const VideoContainer = styled.div``;

const Showcase = () => {
  const [siteLoaded, setSiteLoaded] = useState<boolean>(true);
  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const [posterImage, setPosterImage] = useState<string>("");
  const videoRef: any = useRef(null);
  
  const mainDivRef: any = useRef(null);
  const musicDivRef: any = useRef(null);
  const cdPlayerDivRef: any = useRef(null);
  const videosDivRef: any = useRef(null);
  const tourDivRef: any = useRef(null);
  const storeDivRef: any = useRef(null);

  const musicPlayedTitleRef: any = useRef(null);
  const musicPlayedTitleRef2: any = useRef(null);
  const musicPlayerRef: any = useRef(null);
  const musicPlayerSliderRef: any = useRef(null);
  const musicRotatingCDRef: any = useRef(null);
  const buttonRef: any = useRef(null);
  const buttonPrevRef: any = useRef(null);
  const buttonNextRef: any = useRef(null);

  const videoPlayerDivRef: any = useRef(null);
  const videoPlayedTitleRef: any = useRef(null);
  const videoPreviewImageRef: any = useRef(null);
  
  const tourDiv: any = useRef(null);
  const tourDivContainer: any = useRef(null);

  const [showMusicDiv, setShowMusicDiv] = useState(false);
  const [showVideosDiv, setShowVideosDiv] = useState(false);
  const [showTourDiv, setShowTourDiv] = useState(false);
  const [mouseDownOnSlider, setMouseDownOnSlider] = useState(false);
  const [musicIsPlaying, setMusicIsPlaying] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(0);

  const [minimizeMainDiv, setMinimizeMainDiv] = useState(false);
  const [minimizeMusicDiv, setMinimizeMusicDiv] = useState(false);
  const [minimizeVideoDiv, setMinimizeVideoDiv] = useState(false);
  const [minimizeTourDiv, setMinimizeTourDiv] = useState(false);

  const socialItems = socials.map((item, idx) =>
    !item.show ? null : (
      <a
        key={idx}
        href={item.link}
        target="_blank"
        className="flex flex-col items-center text-[#e62272] font-monomials capitalize text-[0.55rem] md:text-xs px-1.5 md:px-0 py-1.5 md:py-1"
      >
        <img src={item.img} className="w-7 md:w-9" />
        {item.name}
      </a>
    )
  );
  
  async function getTours() {
    //id_15520338
    //id_15484463
    //id_2963536 
    //id_15541181
    const response = await fetch('https://rest.bandsintown.com/artists/id_15541181/events/?app_id=e0132e5e7acb064b84155fa2c2460f6a');
    const json = await response.json();
    const tourContainer = tourDivContainer.current as HTMLDivElement;
    let tours = {};

    if (json.length > 0) {
      json.map((tour: any, idx: any) => {
        let tDate = new Date(tour.datetime);
        let mons = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        tours[idx] = {
          "date": mons[tDate.getMonth()] + " " + tDate.getDate(),
          "location": tour.venue.location,
          "venue": tour.venue.name,
          "url": tour.offers.url
        }
        
        $("#tourContainer").append($('<div class="flex justify-between items-baseline py-2 border-b border-b-[#e62272] text-xs"><div class="flex flex-col md:flex-row justify-between items-baseline w-full mr-3"><div><span class="text-2xl">' + tDate.getDate() + "</span> " + mons[tDate.getMonth()] + "</div><div>" + tour.venue.name + "</div><div>" + tour.venue.location + '</div></div><div class="flex flex-col md:flex-row justify-center items-center"><a href="https://www.bandsintown.com/e/' + tour.id + '?app_id=e0132e5e7acb064b84155fa2c2460f6a&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=event&trigger=rsvp_going" class="border border-[#e62272] px-4 py-1 uppercase w-24 text-xs m-1 hover:bg-[#e62272] hover:text-white text-center" target="_blank">rsvp</a><a href="https://www.bandsintown.com/t/' + tour.id + '?app_id=e0132e5e7acb064b84155fa2c2460f6a&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=ticket" class="border border-[#e62272] px-4 py-1 capitalize w-24 text-xs m-1 hover:bg-[#e62272] hover:text-white text-center" target="_blank">Tickets</a></div></div>'));
      });
    } else {
      const node = document.createElement("div");
      const content = document.createTextNode("TBA");
      node.appendChild(content);
      node.className = "text-7xl flex h-full justify-center items-center";
      tourContainer.appendChild(node);
    }
  }

  // useEffect(() => {
  //   const preloaderVideo = videoRef.current as HTMLVideoElement;
  //   if (!preloaderVideo?.paused) {
  //     setTimeout(function() {
  //         setPosterImage(isMobile ? POSTER_M : POSTER_D);
  //         console.log('test');
  //     }, 1000);
  //   } else {
  //     setPosterImage(isMobile ? POSTER_M : POSTER_D);
  //   }
  // }, [posterImage]);

  useEffect(() => {
    setTimeout(function() {
      const preloaderVideo = videoRef.current as HTMLVideoElement;
      if (preloaderVideo?.paused) {
        setVideoEnded(true);
      }
    }, 5000);
  }, [videoEnded]);

  // useEffect(() => {
  //   setTimeout(function() {
  //     setSiteLoaded(true);
  //   }, (isMobile ? 4900 : 3000));
  // }, [siteLoaded]);

  useEffect(() => {
    setTimeout(() => {
      jQuery(".draggable").draggable({ handle: "div.header" });
    }, 1000);
  }, [])
  
  return (
    <>
      <img src={PAPER_BG} className="h-full w-full object-cover object-left absolute top-0" />

      {!videoEnded ? (
        <VideoContainer
          className={`h-full w-full align-center absolute z-[100] top-0 ${videoEnded ? 'hidden' : 'block'}`}
        >
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="none"
            playsInline
            autoPlay
            muted
            poster={isMobile ? POSTER_M : ""}
            src={isMobile ? VIDEO_M : VIDEO_D}
            onEnded={() => setVideoEnded(true)}
            className="h-full w-full object-cover object-center"
          ></VideoPlayer>
        </VideoContainer>
      // {/* ) : null } */}
      ) : (

      <Box
        id={RoutePattern.HOME}
        className={`relative ${siteLoaded ? 'flex' : 'hidden'} flex-col justify-center items-center h-screen w-full overflow-hidden`}
        onLoad={() => {
          jQuery(".draggable").draggable({ handle: "div.header" });
        }}
      >
        <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
          <Box className="absolute w-full h-screen m-auto flex justify-center items-center">
            <img
              src={LOGO}
              className="absolute top-4 left-4 w-28 md:w-36"
              onClick={() => {
                const openMainRef = mainDivRef.current as HTMLDivElement;
                openMainRef.style.display = 'block';
                $(".draggable").removeClass('active-window');
                $("#mainDiv").addClass('active-window');
              }}
            />
            <div className={`absolute ${iOS ? (CriOS ? 'bottom-28' : 'bottom-20') : 'bottom-16'} md:bottom-2 text-[#e62272] uppercase font-monomials`}>
              chenayder &copy; {new Date().getFullYear()}
            </div>
            <div className="absolute flex flex-col md:right-3 -mt-48 hidden md:block">
              {socialItems}
            </div>
            <div className={`absolute flex flex-row ${iOS ? (CriOS ? 'bottom-[8.5rem]' : 'bottom-[6.5rem]') : 'bottom-[5.5rem]'} md:bottom-8 block md:hidden`}>
              {socialItems}
            </div>
            <div
              ref={mainDivRef}
              id="mainDiv"
              className="draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-10/12 md:w-[40rem] top-[8rem] md:top-[5rem] 3xl:top-[10rem]"
            >
              <div
                className="header flex justify-between items-center p-1"
                onClick={(e) => {
                  $(".draggable").removeClass('active-window');
                  $("#mainDiv").addClass('active-window');
                }}
              >
                <div
                  className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                  onClick={() => {
                    const closeMainRef = mainDivRef.current as HTMLDivElement;
                    closeMainRef.style.display = 'none';
                  }}
                >x</div>
                <div
                  className="flex justify-center items-center border border-[#e62272] w-6 h-3 px-3 leading-3 text-xs ml-1"
                  onClick={() => {
                    if (!minimizeMainDiv) {
                      $("#mainDiv .content").hide();
                    } else {
                      $("#mainDiv .content").show();
                    }

                    setMinimizeMainDiv(prevStatus => !prevStatus);
                  }}
                ><FontAwesomeIcon icon={(!minimizeMainDiv ? faChevronUp : faChevronDown) as any} className="" /></div>
                <div className="w-full flex flex-col mx-1">
                  <span className="h-px bg-[#e62272] my-px"></span>
                  <span className="h-px bg-[#e62272] my-px"></span>
                  <span className="h-px bg-[#e62272] my-px"></span>
                </div>
                <div className="flex justify-center items-center leading-3 uppercase text-xs mt-0.5">chenayder</div>
              </div>
              <div
                className="content mx-1 mb-1 border border-[#e62272] bg-black min-h-[13rem] md:min-h-[24rem]"
                onClick={(e) => {
                  $(".draggable").removeClass('active-window');
                  $("#mainDiv").addClass('active-window');
                }}
              >
                <img src={CHEYNADER} className="object-cover" />
              </div>
              <div className="grid grid-flow-col justify-stretch footer px-1 border-t-2 border-[#e62272] w-full">
                <div className="flex flex-row justify-center items-center pt-4 pb-1 border-r border-[#e62272]">
                  <div
                    className="flex flex-col justify-center items-center uppercase text-xs md:text-sm"
                    onClick={() => {
                      setShowMusicDiv(prevStatus => !prevStatus);
                      // setShowVideosDiv(false);
                      // setShowTourDiv(false);
                      // setMusicIsPlaying(false);
                      setVideoIsPlaying(false);
                      $(".draggable").removeClass('active-window');
                      setTimeout(() => {
                        jQuery(".draggable").draggable({ handle: "div.header" });
                      }, 100);
                    }}
                  >
                    <img src={DISK} className="h-10 md:h-10 3xl:h-12 mb-3" />
                    music
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center pt-4 pb-1 border-x border-[#e62272]">
                  <div
                    className="flex flex-col justify-center items-center uppercase text-xs md:text-sm"
                    onClick={() => {
                      setShowVideosDiv(prevStatus => !prevStatus);
                      // setShowMusicDiv(false);
                      // setShowTourDiv(false);
                      setVideoIsPlaying(false);
                      $(".draggable").removeClass('active-window');
                      setTimeout(() => {
                        jQuery(".draggable").draggable({ handle: "div.header" });
                      }, 100);
                    }}
                  >
                    <img src={VIDEOS} className="h-10 md:h-10 3xl:h-12 mb-3" />
                    videos
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center pt-4 pb-1 border-x border-[#e62272]">
                  <div
                    className="flex flex-col justify-center items-center uppercase text-xs md:text-sm"
                    onClick={() => {
                      setShowTourDiv(prevStatus => !prevStatus);
                      // setShowVideosDiv(false);
                      // setShowMusicDiv(false);
                      setVideoIsPlaying(false);
                      $(".draggable").removeClass('active-window');

                      setTimeout(() => {
                        jQuery(".draggable").draggable({ handle: "div.header" });
                        getTours();
                      }, 100);
                    }}
                  >
                    <img src={TOUR} className="h-10 md:h-10 3xl:h-12 mb-3" />
                    tour
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center pt-4 pb-1 border-l border-[#e62272]">
                  <div className="flex flex-col justify-center items-center uppercase text-xs md:text-sm">
                    <img src={STORE} className="h-10 md:h-10 3xl:h-12 mb-3" />
                    store
                  </div>
                </div>
                <div className="flex flex-row justify-center items-center pt-4 pb-1 border-l border-[#e62272]">
                  <div
                    className="flex flex-col justify-center items-center uppercase text-xs md:text-sm"
                    onClick={() => {
                      window.open('https://www.youtube.com/@whereischenayder');
                    }}
                  >
                    <img src={QMARK} className="h-10 md:h-10 3xl:h-12 mb-3" />
                    ???
                  </div>
                </div>
              </div>
            </div>

            {!showMusicDiv ? null : (
              <>
                <div
                  ref={cdPlayerDivRef}
                  className={`draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-64 md:right-80 3xl:right-[30rem] h-[30rem] bg-gradient-to-b from-white to-[#f9d0d0] top-[10rem] md:top-[8rem] 3xl:top-[13rem]`}
                  onClick={(e) => {
                    $(".draggable").removeClass('active-window');
                    $(e.currentTarget).addClass('active-window');
                  }}
                >
                  <div className="header flex justify-between items-center p-1">
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                      onClick={() => {
                        const closeCDPlayerDiv = cdPlayerDivRef.current as HTMLDivElement;
                        closeCDPlayerDiv.style.display = 'none';

                        const musicPlayedTitle = musicPlayedTitleRef.current as HTMLDivElement;
                        musicPlayedTitle.innerText = "";

                        const musicPlayedTitle2 = musicPlayedTitleRef2.current as HTMLDivElement;
                        musicPlayedTitle2.innerText = "";

                        // const musicPlayer = musicPlayerRef.current as HTMLAudioElement;
                        // musicPlayer.src = "";

                        const rotatingCD = musicRotatingCDRef.current as HTMLImageElement;
                        rotatingCD.src = BLUE_OBLIVION;

                        setMusicIsPlaying(false);
                      }}
                    >x</div>
                  </div>
                  <div className="flex flex-col justify-between h-auto overflow-visible">
                    <div ref={musicPlayedTitleRef} className="text-center mt-2 capitalize text-sm">{musicData[0].name}</div>
                    <div>
                      {/* animate-spin-slow [animation-play-state:paused]  */}
                      <img ref={musicRotatingCDRef} src={BLUE_OBLIVION} className="w-80 h-80 max-w-fit -m-[13%] mt-4 rounded-full border-2 shadow-[2px_2px_1px_rgba(0,0,0,0.2)]"/>
                      <div className="absolute bg-white border-[1.65rem] border-gray-900 rounded-full w-20 h-20 left-1/2 top-[47%] -translate-x-1/2 -translate-y-1/2"></div>
                    </div>
                    <div className="absolute bottom-1 w-full p-2">
                      <div className="flex justify-between -mb-2">
                        <div className="uppercase text-xs">
                          chenayder
                          <div ref={musicPlayedTitleRef2} className="capitalize text-[.65rem]">{musicData[0].name}</div>
                        </div>
                        <a
                          ref={buttonRef}
                          href={musicData[0].link}
                          target="_blank"
                          className="h-6 w-auto leading-4 px-3 py-1 border border-[#e62272] rounded-sm text-xxs uppercase"
                        >
                          {/* <FontAwesomeIcon icon={(!musicIsPlaying ? faPlay : faPause) as any} /> */}
                          listen now
                        </a>
                      </div>
                      {/* <Slider
                        ref={musicPlayerSliderRef}
                        type="range"
                        defaultValue="0"
                        min="0"
                        max="100"
                        className="w-full h-px bg-[#e62272]/50"
                        disabled
                        onMouseDown={() => {
                          setMouseDownOnSlider(true);
                        }}
                        onMouseUp={() => {
                          setMouseDownOnSlider(false);
                        }}
                      />
                      <div className="text-xxs"><span id="musicCurrentTime">00:00</span> / <span id="musicDuration">00:00</span></div> */}
                    </div>
                    {/* <audio
                      ref={musicPlayerRef}
                      src={musicData[0].url}
                      className="hidden"
                      onTimeUpdate={(e) => {
                        const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
                        const rotatingCD = musicRotatingCDRef.current as HTMLImageElement;
                        musicSlider.value = (e.currentTarget.currentTime / e.currentTarget.duration * 100).toString();


                        let totalSeconds = Math.floor(e.currentTarget.currentTime);
                        let hours = Math.floor(totalSeconds / 3600);
                        totalSeconds %= 3600;
                        let minutes = Math.floor(totalSeconds / 60);
                        let seconds = totalSeconds % 60;

                        let minutesSTR = String(minutes).padStart(2, "0");
                        let hoursSTR = String(hours).padStart(2, "0");
                        let secondsSTR = String(seconds).padStart(2, "0");
                        let result = minutesSTR + ":" + secondsSTR;
                        $("#musicCurrentTime").text(result);

                        if (e.currentTarget.currentTime == e.currentTarget.duration) {
                          setMusicIsPlaying(false);
                          rotatingCD.style.animationPlayState = 'paused';
                        }
                      }}
                      onLoadedData={(e) => {
                        const musicSlider = musicPlayerSliderRef.current as HTMLInputElement;
                        const rotatingCD = musicRotatingCDRef.current as HTMLImageElement;
                        musicSlider.value = "0";
                        rotatingCD.style.animationPlayState = 'paused';

                        let totalSeconds = Math.floor(e.currentTarget.duration);
                        let hours = Math.floor(totalSeconds / 3600);
                        totalSeconds %= 3600;
                        let minutes = Math.floor(totalSeconds / 60);
                        let seconds = totalSeconds % 60;

                        let minutesSTR = String(minutes).padStart(2, "0");
                        let hoursSTR = String(hours).padStart(2, "0");
                        let secondsSTR = String(seconds).padStart(2, "0");
                        let result = minutesSTR + ":" + secondsSTR;
                        $("#musicDuration").text(result);
                      }}
                    /> */}
                  </div>
                </div>

                <div
                  id="musicDiv"
                  ref={musicDivRef}
                  className="draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-11/12 md:w-[35rem] 3xl:w-[40rem] md:left-64 3xl:left-96 top-[3.5rem] md:top-[14rem] 3xl:top-[20rem]"
                  onClick={(e) => {
                    $(".draggable").removeClass('active-window');
                    $(e.currentTarget).addClass('active-window');
                  }}
                >
                  <div className="header flex justify-between items-center p-1">
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                      onClick={() => {
                        setShowMusicDiv(false);
                      }}
                    >x</div>
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-6 h-3 px-3 leading-3 text-xs ml-1"
                      onClick={() => {
                        if (!minimizeMusicDiv) {
                          $("#musicDiv .content").hide();
                        } else {
                          $("#musicDiv .content").show();
                        }
      
                        setMinimizeMusicDiv(prevStatus => !prevStatus);
                      }}
                    ><FontAwesomeIcon icon={(!minimizeMusicDiv ? faChevronUp : faChevronDown) as any} className="" /></div>
                    <div className="w-full flex flex-col mx-1">
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                    </div>
                    <div className="flex justify-center items-center leading-3 uppercase text-xs mt-0.5">music</div>
                  </div>
                  <div className="content mx-1 mb-1 border border-[#e62272]">
                    <div className="grid grid-cols-4 md:grid-cols-5 gap-0 md:gap-2 bg-white border-t border-[#e62272] mt-3 max-h-32 md:max-h-72 overflow-y-auto overflow-x-hidden">
                      {musicData.map((item, idx) => 
                        <div className="flex flex-row justify-center items-start" key={idx}>
                          <div
                            className="flex flex-col justify-center items-center capitalize text-xs md:text-sm p-3"
                            onClick={() => {
                              const closeCDPlayerDiv = cdPlayerDivRef.current as HTMLDivElement;
                              closeCDPlayerDiv.style.display = 'block';

                              const musicPlayedTitle = musicPlayedTitleRef.current as HTMLDivElement;
                              musicPlayedTitle.innerText = item.name;

                              const musicPlayedTitle2 = musicPlayedTitleRef2.current as HTMLDivElement;
                              musicPlayedTitle2.innerText = item.name;

                              // const musicPlayer = musicPlayerRef.current as HTMLAudioElement;
                              // musicPlayer.src = item.url;

                              const linkOut = buttonRef.current as HTMLAnchorElement;
                              linkOut.href = item.link;

                              const rotatingCD = musicRotatingCDRef.current as HTMLImageElement;
                              rotatingCD.src = item.img;

                              setMusicIsPlaying(false);
                            }}
                          >
                            <img src={DISK} className="w-10 md:w-14 3xl:w-16 h-10 md:h-14 3xl:h-16 mb-3" />
                            <span className="text-xxs md:text-xs text-center">{item.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            
            {!showVideosDiv ? null : (
              <>
                <div
                  ref={videoPlayerDivRef}
                  className="draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-11/12 md:w-1/2 md:right-72 3xl:right-[30rem] h-auto md:h-[30rem] 3xl:h-[37rem] bg-[#f9d0d0] top-[18rem] md:top-[7rem] 3xl:top-[10rem] p-1"
                  onClick={(e) => {
                    $(".draggable").removeClass('active-window');
                    $(e.currentTarget).addClass('active-window');
                  }}
                >
                  <div className="header flex justify-between items-center pb-1">
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                      onClick={() => {
                        const closeVideoPlayerDiv = videoPlayerDivRef.current as HTMLDivElement;
                        closeVideoPlayerDiv.style.display = 'none';

                        setVideoIsPlaying(false);
                      }}
                    >x</div>
                    <div className="w-full flex flex-col mx-1">
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-between h-auto md:h-[26rem] 3xl:h-[33rem] mb-9 md:mb-0">
                    <div className="w-full h-full border-2 border-[#e62272] flex justify-center items-center overflow-hidden content-center max-h-48 md:max-h-fit bg-black">
                      {!videoIsPlaying ? (
                        <img ref={videoPreviewImageRef} src={`https://img.youtube.com/vi/${videoData[selectedVideo].videoId}/sddefault.jpg`} className="w-full object-cover object-center"/>
                      ) : (
                        <iframe src={`https://www.youtube.com/embed/${videoData[selectedVideo].videoId}?si=rU2ltezH_6bDBLNC&amp;controls=0&amp;autoplay=1&amp;playsinline=1`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen className="h-[192px] md:h-full w-full"></iframe>
                      )}
                    </div>
                    <div className="absolute bottom-1 w-full -m-1">
                      <div className="flex justify-between px-1">
                        <div className="uppercase text-xs">
                          chenayder
                          <div ref={videoPlayedTitleRef} className="capitalize text-[.65rem]">{videoData[selectedVideo].name}</div>
                        </div>
                        <div className="flex">
                          <button
                            ref={buttonPrevRef}
                            className="h-6 w-auto leading-4 px-4 py-1 border border-[#e62272] rounded-sm text-xs uppercase mr-2"
                            onClick={() => {
                              window.open("https://youtu.be/" + videoData[selectedVideo].videoId + "?feature=shared");
                            }}
                          >
                            <FontAwesomeIcon icon={faShareFromSquare as any} className="" />
                          </button>
                          <button
                            ref={buttonPrevRef}
                            className="h-6 w-auto leading-4 px-4 py-1 border border-[#e62272] rounded-sm text-xs uppercase"
                            onClick={() => {
                              if (selectedVideo < 1) {
                                setSelectedVideo(videoData.length - 1);
                              } else {
                                setSelectedVideo(prevIdx => prevIdx - 1);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faBackwardStep as any} className="" />
                          </button>
                          <button
                            ref={buttonRef}
                            className="h-6 w-auto leading-4 px-4 py-1 border border-[#e62272] rounded-sm text-xs uppercase"
                            onClick={() => {
                              setVideoIsPlaying(prevStatus => !prevStatus);
                            }}
                          >
                            <FontAwesomeIcon icon={(!videoIsPlaying ? faPlay : faStop) as any} className="" />
                          </button>
                          <button
                            ref={buttonNextRef}
                            className="h-6 w-auto leading-4 px-5 py-1 border border-[#e62272] rounded-sm text-xs uppercase"
                            onClick={() => {
                              if (selectedVideo >= (videoData.length - 1)) {
                                setSelectedVideo(0);
                              } else {
                                setSelectedVideo(prevIdx => prevIdx + 1);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faForwardStep as any} className="" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div
                  id="videoDiv"
                  ref={videosDivRef}
                  className="draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-10/12 md:w-[35rem] 3xl:w-[40rem] md:left-64 3xl:left-96 top-[5rem] md:top-[10rem] 3xl:top-[20rem]"
                  onClick={(e) => {
                    $(".draggable").removeClass('active-window');
                    $(e.currentTarget).addClass('active-window');
                  }}
                >
                  <div className="header flex justify-between items-center p-1">
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                      onClick={() => {
                        setShowVideosDiv(false);
                      }}
                    >x</div>
                    <div
                      className="flex justify-center items-center border border-[#e62272] w-6 h-3 px-3 leading-3 text-xs ml-1"
                      onClick={() => {
                        if (!minimizeVideoDiv) {
                          $("#videoDiv .content").hide();
                        } else {
                          $("#videoDiv .content").show();
                        }
      
                        setMinimizeVideoDiv(prevStatus => !prevStatus);
                      }}
                    ><FontAwesomeIcon icon={(!minimizeVideoDiv ? faChevronUp : faChevronDown) as any} className="" /></div>
                    <div className="w-full flex flex-col mx-1">
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                      <span className="h-px bg-[#e62272] my-px"></span>
                    </div>
                    <div className="flex justify-center items-center leading-3 uppercase text-xs mt-0.5">videos</div>
                  </div>
                  <div className="content mx-1 mb-1 border border-[#e62272]">
                    <div className="grid grid-cols-4 md:grid-cols-5 gap-0 md:gap-2 bg-white border-t border-[#e62272] mt-3 max-h-40 md:max-h-72 overflow-y-auto overflow-x-hidden">
                      {videoData.map((item, idx) => 
                        <div className="flex flex-row justify-center items-start" key={idx}>
                          <div
                            data-id={idx}
                            className="flex flex-col justify-center items-center capitalize text-xs md:text-sm p-3 md:p-4"
                            onClick={() => {
                              const closeVideoPlayerDiv = videoPlayerDivRef.current as HTMLDivElement;
                              closeVideoPlayerDiv.style.display = 'block';

                              const videoPlayedTitle = videoPlayedTitleRef.current as HTMLDivElement;
                              videoPlayedTitle.innerText = item.name;

                              const videoPreviewImage = videoPreviewImageRef.current as HTMLImageElement;
                              videoPreviewImage.src = `https://img.youtube.com/vi/${item.videoId}/sddefault.jpg`;

                              setSelectedVideo(idx);
                              setVideoIsPlaying(false);
                            }}
                          >
                            <img src={VID_PLAYER} className="w-10 md:w-14 3xl:w-16 h-10 md:h-14 3xl:h-16 mb-3" />
                            <span className="text-xxs md:text-xs text-center">{item.name}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            
            {!showTourDiv ? null : (
              <div
                id="tourDiv"
                ref={tourDivRef}
                className="draggable absolute bg-[#f9d0d0] border-2 border-[#e62272] font-monomials text-[#e62272] shadow-[5px_5px_1px_rgba(0,0,0,0.2)] w-11/12 md:w-3/5 h-[35rem] md:h-[30rem] bg-[#f9d0d0] top-[5rem] md:top-[7rem] 3xl:top-[13rem] p-1"
                onClick={(e) => {
                  $(".draggable").removeClass('active-window');
                  $(e.currentTarget).addClass('active-window');
                }}
              >
                <div className="header flex justify-between items-center pb-1">
                  <div
                    className="flex justify-center items-center border border-[#e62272] w-3 h-3 px-1 leading-3 text-xs"
                    onClick={() => {
                      setShowTourDiv(false);
                    }}
                  >x</div>
                  {/* <div
                    className="flex justify-center items-center border border-[#e62272] w-6 h-3 px-3 leading-3 text-xs ml-1"
                    onClick={() => {
                      if (!minimizeVideoDiv) {
                        $("#tourDiv .content").hide();
                      } else {
                        $("#tourDiv .content").show();
                      }
    
                      setMinimizeVideoDiv(prevStatus => !prevStatus);
                    }}
                  ><FontAwesomeIcon icon={(!minimizeVideoDiv ? faChevronUp : faChevronDown) as any} /></div> */}
                  <div className="w-full flex flex-col mx-1">
                    <span className="h-px bg-[#e62272] my-px"></span>
                    <span className="h-px bg-[#e62272] my-px"></span>
                    <span className="h-px bg-[#e62272] my-px"></span>
                  </div>
                  <div className="flex justify-center items-center leading-3 uppercase text-xs mt-0.5">tour</div>
                </div>
                <div className="w-full absolute bottom-3 -mx-1">
                  <img src={BANDISTOWN} className="w-52 md:w-72 m-auto" />
                </div>
                <div className="content mx-1 mb-1 border border-[#e62272] flex flex-col justify-strech items-center h-[29rem] md:h-[24.5rem]">
                  <div ref={tourDivContainer} id="tourContainer" className="bg-white border-t border-[#e62272] mt-3 w-full h-full px-2 pb-1 overflow-y-auto overflow-x-hidden"></div>
                </div>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      )}
    </>
  );
};

export default Showcase;
