import INSTAGRAM from "../../../assets/images/Instagram.png";
import TWITTER from "../../../assets/images/Twitter.png";
import TUMBLR from "../../../assets/images/Tumblr.png";
import TIKTOK from "../../../assets/images/TikTok.png";
import SNAPCHAT from "../../../assets/images/Snapchat.png";
import YOUTUBE from "../../../assets/images/Youtube.png";

import BLUE_OBLIVION from "../../../assets/images/music/blue-oblivion.jpg";

export const socials = [
    {
        name: "Instagram",
        img: INSTAGRAM,
        link: "https://chenayder.lnk.to/instagram",
        show: true
    },
    {
        name: "Twitter",
        img: TWITTER,
        link: "https://chenayder.lnk.to/twitter",
        show: true
    },
    {
        name: "Tumblr",
        img: TUMBLR,
        link: "https://chenayder.lnk.to/tumblr",
        show: true
    },
    {
        name: "TikTok",
        img: TIKTOK,
        link: "https://chenayder.lnk.to/tiktok",
        show: true
    },
    {
        name: "Snapchat",
        img: SNAPCHAT,
        link: "https://chenayder.lnk.to/snapchat",
        show: true
    },
    {
        name: "YouTube",
        img: YOUTUBE,
        link: "https://www.youtube.com/@chenaydermusic",
        show: true
    },
];

export const musicData = [
    {
        name: "Blue Oblivion",
        link: "https://chenayder.lnk.to/blueoblivion",
        img: "https://linkstorage.linkfire.com/medialinks/images/c3aeeb7c-aec9-49bb-97ce-4e3df50bd996/artwork-440x440.jpg",
        show: true,
    },
    {
        name: "Goodbye",
        link: "https://chenayder.lnk.to/goodbye",
        img: "https://linkstorage.linkfire.com/medialinks/images/2bfdab56-a187-4566-93f5-84cc2079b82d/artwork-440x440.jpg",
        show: true,
    },
    {
        name: "For One Last Time",
        link: "https://chenayder.lnk.to/folt",
        img: "https://linkstorage.linkfire.com/medialinks/images/686b133c-2400-4127-aa89-37fff725a15f/artwork-440x440.jpg",
        show: true,
    },
    // {
    //     name: "Colors",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "Strawberry Perfume",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "Off The Wall",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "Save U",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "Sweatshirt",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "My My",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
    // {
    //     name: "Fall",
    //     link: "",
    //     img: BLUE_OBLIVION,
    //     show: true,
    // },
];

export const videoData = [
    {
        name: "For One Last Time",
        videoId: "aqSUgo79eLQ"
    },
    {
        name: "Colors",
        videoId: "1Z8Ugp4eB68"
    },
    {
        name: "Goodbye",
        videoId: "94z3uwM8ym4"
    },
    {
        name: "Strawberry Perfume",
        videoId: "nhS6tyxLoMU"
    },
    {
        name: "Off the Wall",
        videoId: "IkEPdCPhqJs"
    },
    {
        name: "Save U",
        videoId: "n9HExTo4Zc8"
    },
    {
        name: "Sweatshirt",
        videoId: "ACZKSzpy6ms"
    },
    {
        name: "My My",
        videoId: "JugZtxLcIJg"
    },
    {
        name: "Fall",
        videoId: "yqKtASK80Rk"
    },
]